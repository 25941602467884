@import 'fonts/feather.css';
@import 'fonts/socicon.css';
@import 'fonts/Pe-icon-7.css';
@import 'css/vendor.min.css';
@import 'css/styles.min.css';
@import 'icofont/icofont.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-show-overflow {
  @apply tw-overflow-visible !important;
}

.home-swiper .swiper-container {
  @apply tw--mx-4 sm:tw-mx-0;
}

.home-swiper .swiper-wrapper {
  @apply tw-px-4 sm:tw-px-0;
}

.home-swiper .swiper-slide {
  @apply tw-w-4/5 sm:tw-w-full;
}

.ReactModal__Overlay {
  /*opacity: 0;*/
  /*transition: opacity 2000ms ease-in-out;*/
  @apply tw-transition tw-ease-in-out tw-opacity-0 tw-duration-200;
}

.ReactModal__Overlay--after-open {
  @apply tw-opacity-100;
}

.ReactModal__Overlay--before-close {
  @apply tw-opacity-0;
}

.homeExperienceSwiper.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: linear;
}

.homeExperienceSwiper > .swiper-wrapper {
  @apply tw-items-center;
}

/* RC Slider */
.rc-slider .rc-slider-track {
  @apply tw-bg-primary;
}

.rc-slider .rc-slider-handle {
  @apply tw-bg-white tw-border tw-border-greyscale-separator tw-opacity-100 tw-w-5 tw-h-5 tw--mt-2 hover:tw-border-greyscale-separator active:tw-border-greyscale-separator active:tw-ring-2 active:tw-ring-greyscale-placeholder/40;
}

.rc-slider .rc-slider-handle.rc-slider-handle-dragging {
  @apply tw-border tw-border-greyscale-separator tw-ring-4 tw-ring-greyscale-placeholder/40 !important;
}

textarea {
  @apply tw-overflow-auto tw-resize-y;
}

.oblo {
  @apply tw-w-20 tw-h-20 tw-rounded-full tw-text-greyscale-gray-darker tw-text-4xl tw-bg-greyscale-gray-lighter tw-border tw-border-greyscale-gray-light !important;
  line-height: calc(2px + 5rem)!important;
}

.tw-button {
  @apply tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-tracking-wider tw-rounded-md tw-uppercase tw-text-center tw-font-medium tw-no-underline tw-align-middle tw-duration-300 tw-px-4 tw-py-2;

  &.tw-btn-sm {
    @apply tw-leading-8 tw-h-8 tw-px-6 !important;
  }

  &.tw-btn-md {
    @apply tw-px-8 tw-h-10 !important;
  }

  &.tw-btn-lg {
    @apply tw-px-10 tw-leading-[3rem] tw-h-12 !important;
  }

  &.tw-btn-primary {
    @apply tw-text-white tw-bg-primary hover:tw-bg-primary-dark hover:tw-text-white !important;
  }

  &.tw-btn-primary-o {
    @apply tw-text-primary tw-bg-transparent tw-border tw-border-primary hover:tw-bg-primary hover:tw-border-transparent hover:tw-text-white !important;
  }

  &.tw-product-btn {
    @apply tw-py-0 tw-px-2 tw-transform-none tw-normal-case tw-m-0 tw-tracking-wide !important;
  }

  &.tw-btn-success {
    @apply tw-text-white tw-bg-success hover:tw-bg-success-dark hover:tw-text-white !important;
  }
}

.tw-section-container {
  @apply tw-mx-auto xl:tw-w-[1170px] xl:tw-max-w-[1170px] tw-px-4 xl:tw-px-0 !important;
}

.tw-product-item {
  @apply tw-p-0 tw-rounded-2xl tw-overflow-hidden hover:tw-shadow-md max-sm:tw-rounded-none max-sm:hover:tw-shadow-none !important;
}

.tw-product-card {
  @apply tw-block tw-relative tw-w-full tw-p-4 tw-border tw-border-greyscale-gray-light tw-rounded-md tw-bg-white !important;
}